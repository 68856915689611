<div class="container mt-5 mb-5" [ngStyle]="{'text-align':text_align,'direction':text_dir}">

  <mat-card>
    <mat-card-header>
      <mat-card-title>{{locale.user_registration_settings}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>

      <form (submit)="save()" #loginForm=ngForm class="mt-5" *ngIf="registration_settings">

        <div class="row">

          <div class="col-lg-4 col-xl-4 col-md-4 col-sm-12 mt-5">

            <mat-slide-toggle name="allow_manual"
                              [(ngModel)]="registration_settings.allow_manual">{{locale.allow_manual_registration}}</mat-slide-toggle>


          </div>


          <div class="col-lg-4 col-xl-4 col-md-4 col-sm-12 mt-5">

            <mat-slide-toggle name="request_phone" *ngIf="registration_settings.allow_manual"
                              [(ngModel)]="registration_settings.request_phone">{{locale.ask_for_phone}}</mat-slide-toggle>

          </div>

          <div class="col-lg-4 col-xl-4 col-md-4 col-sm-12 mt-5">

            <mat-slide-toggle name="request_email" *ngIf="registration_settings.allow_manual"
                              [(ngModel)]="registration_settings.request_email">{{locale.require_email}}</mat-slide-toggle>

          </div>


        </div>


        <div class="row">

          <div class="col-lg-4 col-xl-4 col-md-4 col-sm-12 mt-5">

            <mat-slide-toggle name="allow_mac"
                              [(ngModel)]="registration_settings.allow_mac">{{locale.register_with_mac}}</mat-slide-toggle>


          </div>


          <div class="col-lg-4 col-xl-4 col-md-4 col-sm-12 mt-5">
            <mat-checkbox name="pass_equal_mac" *ngIf="registration_settings.allow_mac"
                          [(ngModel)]="registration_settings.pass_equal_mac">{{locale.pass_equals_mac}}</mat-checkbox>
          </div>

          <div class="col-lg-4 col-xl-4 col-md-4 col-sm-12 mt-4">

            <mat-form-field *ngIf="registration_settings.allow_mac && !registration_settings.pass_equal_mac">
              <mat-label>{{locale.default_password}}</mat-label>
              <input matInput name="mac_password" [(ngModel)]="registration_settings.mac_password">
            </mat-form-field>


          </div>


          <div class="col-md-12 mt-3">
            <mat-checkbox name="restrict_random_mac " *ngIf="registration_settings.allow_mac"
                          [(ngModel)]="registration_settings.restrict_random_mac">{{locale.restrict_random_mac}}</mat-checkbox>
          </div>



          <div class="col-lg-6 mt-5">
            <mat-checkbox name="request_phone_mac" *ngIf="registration_settings.allow_mac"
                          [(ngModel)]="registration_settings.request_phone_mac">{{locale.request_phone_mac}}</mat-checkbox>
          </div>


          <div class="col-lg-6 mt-3">
            <mat-checkbox name="force_phone_mac" *ngIf="registration_settings.allow_mac && registration_settings.request_phone_mac"
                          [(ngModel)]="registration_settings.force_phone_mac">{{locale.force_phone_mac}}</mat-checkbox>
          </div>




          <div class="col-lg-6 mt-3">
            <mat-checkbox name="request_phone_on_charge"
                          [(ngModel)]="registration_settings.request_phone_on_charge">{{locale.request_phone_on_charge}}</mat-checkbox>
          </div>


          <div class="col-lg-6 mt-3">
            <mat-checkbox name="force_phone_on_charge" *ngIf="registration_settings.request_phone_on_charge"
                          [(ngModel)]="registration_settings.force_phone_on_charge">{{locale.force_phone_on_charge}}</mat-checkbox>
          </div>


        </div>
        <div class="mt-5" *ngIf="registration_settings.allow_mac">


          <mat-label class="mt-3">{{locale.add_lines_to_hotspot}}</mat-label>

          <!-- HTML generated using hilite.me -->
          <div class="mt-3"
               style="direction: ltr; background: #ffffff; overflow:auto;width:auto;border:solid gray;border-width:.1em .1em .1em .8em;padding:.2em .6em;"><pre
            style="margin: 0;height: 100px;"><span style="color: #000080; font-weight: bold">&lt;a</span> <span
            style="color: #FF0000">target=</span><span style="color: #0000FF">&quot;_blank&quot;</span> <span
            style="color: #FF0000">href=</span><span style="color: #0000FF">&quot;http://snono.org/user-pro/#/new-user/$(mac)&quot;</span><span
            style="color: #000080; font-weight: bold">&gt;</span>
<span style="color: #000080; font-weight: bold">&lt;input</span> <span style="color: #FF0000">class=</span><span
              style="color: #0000FF">&quot;btn btn-login&quot;</span> <span style="color: #FF0000">type=</span><span
              style="color: #0000FF">&quot;button&quot;</span><span style="color: #FF0000">value=</span><span
              style="color: #0000FF">&quot;{{locale.new_user}}&quot;</span> <span
              style="color: #FF0000">style=</span><span
              style="color: #0000FF">&quot;font-weight: 700px&quot;</span><span
              style="color: #000080; font-weight: bold">&gt;&lt;/a&gt;</span>
</pre>
          </div>


        </div>


        <div style="text-align: end; margin: 10px;" class="mt-3">

          <button mat-flat-button color="primary" type="submit">{{locale.save}}</button>

        </div>


      </form>


    </mat-card-content>


  </mat-card>

</div>
